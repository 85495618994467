import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getBrowserLang, provideTransloco, TranslocoModule } from '@ngneat/transloco';
import { provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';

import { environment } from '../environments/environment';
import { TranslocoHttpLoader } from './transloco-loader';
import { LangCodes } from './types/lang-codes.enum';

export const supportedLangs = [LangCodes.english, LangCodes.deutsch];

function getDefaultLang(): string {
  const browserLang = getBrowserLang() || '';
  return supportedLangs.includes(browserLang.toLowerCase() as LangCodes)
    ? browserLang
    : LangCodes.english;
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [LangCodes.english, LangCodes.deutsch],
        defaultLang: getDefaultLang(),
        // for optimizations
        // flatten: {
        //   aot: environment.production, // TODO: check on the dev environment
        // },
        prodMode: environment.production,
        reRenderOnLangChange: true,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
    }),
    provideTranslocoMessageformat(),
  ],
  exports: [TranslocoModule],
})
export class TranslocoRootModule {}
